import { Component } from 'react';
import { motion } from 'framer-motion';
import logger from './logger';

// Gets the display name of a JSX component for dev tools
export const getDisplayName = (Component) => Component.displayName || Component.name || 'Component';

const postVariants = {
	initial: { scale: 0.96, y: 30, opacity: 0 },
	enter: {
		scale: 1,
		y: 0,
		opacity: 1,
		transition: { duration: 0.5, ease: [0.48, 0.15, 0.25, 0.96] }
	},
	exit: {
		scale: 0.6,
		y: 100,
		opacity: 0,
		transition: { duration: 0.2, ease: [0.48, 0.15, 0.25, 0.96] }
	}
};

export const withAnimation = (WrappedComponent) =>
	class extends Component {
		static displayName = `withAnimation(${getDisplayName(WrappedComponent)})`;

		static async getInitialProps(ctx) {
			const componentProps =
				WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));

			// logger.info(ctx.pathname);

			// this a hack to remove withAnimation return empty object warning!
			return { ...componentProps, warning: false };
		}

		render() {
			return (
				<motion.div
					initial="initial"
					animate="enter"
					exit="exit"
					variants={{ exit: { transition: { staggerChildren: 0.1 } } }}>
					<motion.div variants={postVariants}>
						<WrappedComponent {...this.props} />
					</motion.div>
				</motion.div>
			);
		}
	};
